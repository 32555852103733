
import React from 'react';

import './App.css';
import './menu.css';
import Menu from './menu.js';




import LayoutCards from './components/motion/LayoutCards'
function App() {

/*const [myclase,setClase] = useState("cp-transition__guide-wrapper_b");
setClase ={setClase}*/
  return (
    <> 
    <div id="outer-container" style={{ height: '100%' }}>
      
      <Menu menu="stack"  menuType={2}></Menu>
      
      <main id="page-wrap">
<div className="guide__component-wrapper">

  <div className="cp-transition__guide-wrapper_b">
    
      <LayoutCards />
      </div>
     
    </div>
    </main>
      </div>
 </> );
}

export default App;
