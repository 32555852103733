import React,{useRef} from 'react';
import cx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import TextInfoContent from '@mui-treasury/components/content/textInfo';
import { useFourThreeCardMediaStyles } from '@mui-treasury/styles/cardMedia/fourThree';
import { useN04TextInfoContentStyles } from '@mui-treasury/styles/textInfoContent/n04';
import { useOverShadowStyles } from '@mui-treasury/styles/shadow/over';

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 343,
    margin: 'auto',
    borderRadius: 12,
    padding: 12,
  },
  media: {
    borderRadius: 6,
  },
}));

export default function MusicCardDemo({set,ActiveItem,item} ) {
  const itemEl = useRef(null);
  const styles = useStyles();
  const mediaStyles = useFourThreeCardMediaStyles();
  const textCardContentStyles = useN04TextInfoContentStyles();
  const shadowStyles = useOverShadowStyles({ inactive: true });

 if(ActiveItem === item && ActiveItem >= 0){
  //console.log("ActiveItem card home",ActiveItem,item)
   window.scrollTo({
    top: itemEl.current.offsetTop,
    behavior: "smooth" // optional
  }) };
  
  //https://images.unsplash.com/photo-1500462918059-b1a0cb512f1d?ixlib=rb-1.2.1&auto=format&fit=crop&w=934&q=80
  
  return (<>
     <div   ref={itemEl} > {item}</div>
    <Card key={`cardHome-${item}`} className={cx(styles.root, shadowStyles.root)}>
      <CardMedia
        className={cx(styles.media, mediaStyles.root)}
        image={
          `https://www.bearfilms.com/tour/content2/${set.Directory}/${set.previewvidposter}`
        }
      />
      <CardContent>
        <TextInfoContent 
        
          classes={textCardContentStyles}
          overline={set.title}
          heading={'Scene'}
          body={"That year cami, collection of songs, review melodies, memories full, this is a long and warm journey"}
        />
     
      </CardContent>
    </Card>
   
    </>
  );
};
