import React, { Component } from 'react';

import BurgerMenu from 'react-burger-menu';
import classNames from 'classnames';
import MenuWrap from './MenuWrap';
import { Home, LabelImportant, Email } from '@material-ui/icons';

const menus = {
    slide: { buttonText: 'Slide', items: 1 },
    stack: { buttonText: 'Stack', items: 1 },
    elastic: { buttonText: 'Elastic', items: 1 },
    bubble: { buttonText: 'Bubble', items: 1 },
    push: { buttonText: 'Push', items: 1 },
    pushRotate: { buttonText: 'Push Rotate', items: 2 },
    scaleDown: { buttonText: 'Scale Down', items: 2 },
    scaleRotate: { buttonText: 'Scale Rotate', items: 2 },
    fallDown: { buttonText: 'Fall Down', items: 2 },
    reveal: { buttonText: 'Reveal', items: 1 }
  };
export default class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentMenu: (this.props.menu)? this.props.menu : 'pushRotate',
      side: 'left'
    };
  }

  changeMenu(menu) {
    this.setState({ currentMenu: menu });
  }

  changeSide(side) {
    this.setState({ side });
  }

  getItems() {
    let items;
console.log(menus,this.props.menuType);
    switch (menus[this.props.menu].items) {
      case 1:
        items = [
          <a key="0" href="/#" onClick={()=>this.changeMenu('reveal')}>
            <Home style={{ verticalAlign:"middle" }}fontSize="small"/>
            <span>Reveal</span>
          </a>,
          <a key="1" href="/#" onClick={()=>this.changeMenu('bubble')}>
            <i className="fa fa-fw fa-bell-o" />
            <span>Bubble</span>
          </a>,
          <a key="2" href="/#" onClick={()=>this.changeMenu('push')}>
            <i className="fa fa-fw fa-envelope-o" />
            <span>Push</span>
          </a>,
          <a key="3" href="/#" onClick={()=>this.changeMenu('slide')}>
            <i className="fa fa-fw fa-comment-o" />
            <span>slide</span>
          </a>,
          <a key="4" href="/#" onClick={()=>this.changeMenu('pushRotate')}>
            <i className="fa fa-fw fa-bar-chart-o" />
            <span>push Rotate</span>
          </a>,
          <a key="5" href="/#" onClick={()=>this.changeMenu('stack')}>
            <i className="fa fa-fw fa-newspaper-o" />
            <span>stack</span>
          </a>,
          <a key="6" href="/#" onClick={()=>this.changeMenu('elastic')}>
            <i className="fa fa-fw fa-newspaper-o" />
            <span>elastic</span>
          </a>,
          <a key="7" href="/#" onClick={()=>this.changeMenu('scaleDown')}>
            <i className="fa fa-fw fa-newspaper-o" />
            <span>scale Down</span>
          </a>,
          <a key="8" href="/#" onClick={()=>this.changeMenu('scaleRotate')}>
            <i className="fa fa-fw fa-newspaper-o" />
            <span>scale Rotate</span>
          </a>,
          <a key="8" href="/#" onClick={()=>this.changeMenu('fallDown')}>
            <i className="fa fa-fw fa-newspaper-o" />
            <span>fall Down</span>
          </a>,
        ];
        break;
    
        /**************** MENU OK ****************** */
        default:  items = [
            <a key="0" href="/#" onClick={()=>this.changeMenu('reveal')}>
            <Home style={{ verticalAlign:"middle" }}fontSize="small"/>
            <span>Reveal</span>
          </a>,
          <a key="1" href="/#" onClick={()=>this.changeMenu('bubble')}>
            <i className="fa fa-fw fa-bell-o" />
            <span>Bubble</span>
          </a>,
          <a key="2" href="/#" onClick={()=>this.changeMenu('push')}>
            <i className="fa fa-fw fa-envelope-o" />
            <span>push</span>
          </a>,
          <a key="3" href="/#" onClick={()=>this.changeMenu('reveal')}>
            <i className="fa fa-fw fa-comment-o" />
            <span>Comments</span>
          </a>,
          <a key="4" href="/#" onClick={()=>this.changeMenu('pushRotate')}>
            <i className="fa fa-fw fa-bar-chart-o" />
            <span>pushRotate</span>
          </a>,
          <a key="5" href="/#" onClick={()=>this.changeMenu('reveal')}>
            <i className="fa fa-fw fa-newspaper-o" />
            <span>Reading List</span>
          </a>
          ];
          break;
    }

    return items;
  }

  getMenu() {
    const Menu = BurgerMenu[this.state.currentMenu];

    return (
      <MenuWrap wait={20} side={this.state.side}>
        <Menu
          id={this.state.currentMenu}
          pageWrapId={'page-wrap'}
          outerContainerId={'outer-container'}
          right={this.state.side === 'right'}
        >
          {this.getItems()}
        </Menu>
      </MenuWrap>
    );
  }

  render() {
   

    return (
      <>
        {this.getMenu()}
        </>
    );
  }
}



