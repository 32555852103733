import React, { useState, useEffect } from 'react';

import { motion, AnimateSharedLayout, AnimatePresence } from 'framer-motion';
import ClipPathTransition from './ClipPathTransition'
import MusicCardDemo from '../cardHome'
import '../../styles/index.scss'
import { useInView } from 'react-intersection-observer';
//{ setClase }
const ppagina = 20;
export default function AppOpeningAnimation() {
  const [canOpenApp, setCanOpenApp] = useState(true);
  const [openedApp, setOpenedApp] = useState(null);
  const [Itmes, setItems] = useState(null);
  const [Itmesall, setItemsall] = useState(null);
  const [actualIDX, ChangeItem] = useState(-1);
  const [seekItmevalue, SeekItemME] = useState(-1);
  const [classLoadmore, setClassLoadmore] = useState('noloadMore');

  const closeApp = () => {
    setOpenedApp(null);
    setCanOpenApp(false);
    //setClase("cp-transition__guide-wrapper_b");
    setTimeout(() => {
      setCanOpenApp(true);
    }, 500);
  }
  const onDragEnd = (event, info) => {
    if (info.offset.y < 0) closeApp();
  }
  //console.log("ActiveItem --> layout=>",actualIDX)
  useEffect(() => {
    fetch(`https://www.bearfilms.com/out/tour/api-rest/setLoader_v4.php?home=14&page=1`)
      .then(res => res.json())
      .then(
        (result) => {

          setItemsall(result[0]);
          let pagina = [];
          result[0].map((app, i) => { if (i < ppagina) { pagina.push(app) } return i; });
          setItems(pagina);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          console.log("error", error);
        }
      )
  }, [])
  const { ref, inView,  } = useInView({
    
    threshold: 0,
  });
  useEffect(() => {
    if (inView && Itmes) {
      let pagina = [];
      pagina = Itmes;
      let p = Number.parseInt(Itmes.length) + ppagina;

      Itmesall.map((app, i) => {

        if (Number.parseInt(i) > Number.parseInt(Itmes.length) && i < p) {
          pagina.push(app);


        }
        return i;
      });
      setItems(pagina);
      setClassLoadmore('loadMore')
    }else{
      setClassLoadmore('noloadMore')
    }
  }, [inView, Itmes, Itmesall]);

  if ((Itmes) && actualIDX > Itmes.length - ppagina) {
    let pagina = [];
    pagina = Itmes;
    let p = Number.parseInt(Itmes.length) + ppagina;

    Itmesall.map((app, i) => {

      if (Number.parseInt(i) > Number.parseInt(Itmes.length) && i < p) {
        pagina.push(app);


      }
      return i;
    });
    setItems(pagina);

  }

  return (
    <div className="app-opening-animation">
      <div className="wrapper-animate">
        <AnimateSharedLayout>
          {Itmes && Itmes.map((app, i) => (
            <motion.div

              className="app-icon__bg"
              key={app.Id}
              layoutId={app.Id}
              onClick={() => { if (canOpenApp) { setOpenedApp(app.Id);ChangeItem(i) } }}
            >

              <MusicCardDemo set={app} item={i} ActiveItem={actualIDX} />
            </motion.div>
          ))}
          <AnimatePresence>
            {openedApp && (
              <>
                <motion.div
                  style={{ zIndex: 1 }}
                  className="app-opened"
                  layoutId={1}

                >   <ClipPathTransition key={`Clip-${openedApp}`} Itmes={Itmes} myFunciton={ChangeItem} seekItemCliked={seekItmevalue} ActiveItem={actualIDX} ChangeItem={ChangeItem} SeekItemME={SeekItemME} />
                </motion.div>
                <motion.div
                  onDragEnd={onDragEnd}
                  drag
                  dragConstraints={{
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0
                  }}
                  dragElastic={.03}

                  whileTap={{
                    scale: .9,
                    borderRadius: '15px',
                  }}
                  style={{ height: "50px" }}
                  className="close-bar"
                  initial={{ y: 8, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: 8, opacity: 0 }}
                  transition={{ ease: 'linear' }}
                  onClick={() => closeApp()}
                />
              </>
            )}
          </AnimatePresence>
        </AnimateSharedLayout>

        <div ref={ref} className={classLoadmore}>
          <h2>{`Header inside viewport ${inView}.`}</h2>
        </div>
      </div>
    </div>
  )
}