import React, { useState, useRef,useEffect } from 'react';
//import cx from 'clsx';
//import { makeStyles } from '@material-ui/core/styles';
//import { useFourThreeCardMediaStyles } from '@mui-treasury/styles/cardMedia/fourThree';
//import { useN04TextInfoContentStyles } from '@mui-treasury/styles/textInfoContent/n04';
//import { useOverShadowStyles } from '@mui-treasury/styles/shadow/over';
import { motion, AnimatePresence } from 'framer-motion';
import { Swiper, SwiperSlide } from 'swiper/react';
import { isMobile } from 'react-device-detect';
import Card from '@material-ui/core/Card';
/* import CardMedia from '@material-ui/core/CardMedia'; */
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import TextInfoContent from '@mui-treasury/components/content/textInfo';
import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import VideoApp from './../Video'
// Import Swiper styles
import 'swiper/swiper.scss';
import '../../styles/index.scss'
import MycardSlider from '../cardSlider'
/*const useStyles = makeStyles(() => ({
  root: {
  
    margin: 'auto',
    borderRadius: 12,
    padding: 12,
  },
  media: {
    borderRadius: 6,
  },
}));*/
const wrapperVariants = {

  initial: {
    clipPath: 'polygon(0 0, 0 0, 0 100%, 0% 100%)',
    transition: { duration: .4 }
  },
  animate: {
    clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',//clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
    transition: { duration: .4, staggerChildren: .1 }
  },
  exit: {
    clipPath: 'polygon(100% 0, 100% 0, 100% 100%, 100% 100%)',
    transition: { duration: .4 }
  }

}

const squareVariants = {
  initial: {
    opacity: 0,
    scale: .3,
  },
  animate: {
    opacity: 1,
    scale: 1,
  }

}

export default function ClipPathTransition({ Itmes, myFunciton, seekItemCliked, ActiveItem, ChangeItem,SeekItemME }) {
  const swiperRef = useRef(null);
  const [selectedSquare, setSelectedSquare] = useState(null);
  const [prevSlide, toggleprevSlide] = useState(-1);
  const [afterBig, toggleafterBig] = useState(null);
  const [sliderItems, setSliderItems] = useState(null);
  const [loadSlides, setLoadSlides] = useState(null);
  const [titlepage, setTitlePage] = useState(null);

  const toSlide = (num) => {
    swiperRef.current?.swiper.init()
    swiperRef.current?.swiper.slideTo(num);
  };

  if (seekItemCliked > 0 && prevSlide !== seekItemCliked) {
    setTimeout(function () { toSlide(seekItemCliked) }, 1000);
    toggleprevSlide(seekItemCliked);
  }
  useEffect(() => {
    if(loadSlides){
      setLoadSlides(null)
      let pagina=[];

      Itmes.map((app,i) => { if( (ActiveItem-10)< i && i < (ActiveItem+10)){ pagina.push(app)}return i;});
       setSliderItems(pagina)
     //  console.log("pagina 2=>>",pagina);
       
    }
  },[ActiveItem,Itmes,loadSlides,sliderItems])
  useEffect(() => {
    document.title = `== ${titlepage} ==`
 }, [titlepage]);
  const renderSquares = () => {
    //const squares = ['yellow', 'green', 'blue', 'violet'];
    return Itmes.map((app, i) => (
      <SwiperSlide key={`slide-${i}`}>
        <motion.div
         
          key={`items-${i}`}
          onClick={() => { toggleafterBig(i); setSelectedSquare(i);ChangeItem(i); setTitlePage(app.title); }}
          variants={squareVariants}
          transition={{ duration: .2, type: 'spring' }}

        >
          <MycardSlider key={`Card-a-${i}`} set={app} />
        </motion.div>
      </SwiperSlide>
    ));

  }
  const onDragEnd = (event, info) => {
    
    if (info.offset.y > -200 || info.offset.y < -240) {
      
       //ACA CAMBIE EL 26 17:45 
      setTimeout(function () { toSlide(afterBig); /*ChangeItem(afterBig)*/ }, 1500);
      toggleprevSlide(afterBig);
      //console.log("onDragEnd", seekItemCliked, selectedSquare, ActiveItem, "==>", afterBig)
      /*SeekItemME(ActiveItem);*/
      setSelectedSquare(null);
    }

  }
  return (

    <div key="div1" className={`cp-transition cp-transition__container cp-transition__container--${selectedSquare}`}>
      <AnimatePresence key="animate" exitBeforeEnter initial={false}>
        {selectedSquare

          ? (
            <motion.div
              style={{ zIndex: 1, }}
              className={`card card__wrapper card__wrapper--${selectedSquare}`}
              key="card"
              variants={wrapperVariants}
              initial="initial"
              animate="animate"
              exit="exit"
              drag
              onDragEnd={onDragEnd}
            >
              <Card >
                <CardHeader

                  action={
                    <IconButton aria-label="settings"  onClick={() => { setSelectedSquare(null) }} >
                      <HighlightOffIcon/>
                    </IconButton>
                  }
                  title={Itmes[afterBig].title}

                />
                {/* <CardMedia
                  component="img"
                  alt="Contemplative Reptile"
                  height="140"
                  width="150"
                  image={ `https://www.bearfilms.com/tour/content2/${Itmes[afterBig].Directory}/${Itmes[afterBig].previewvidposter}`}
                  title="Contemplative Reptile"
                /> */}


                <CardContent>

                  <TextInfoContent

                  
                  
                    body={
                      'That year, collection of songs, review melodies, memories full, this is a long and warm journey That year, collection of songs, review melodies, memories full, this is a long and warm journey' +
                      'That year, collection of songs, review melodies, memories full, this is a long and warm journey' +
                      ' That year, collection of songs, review melodies, memories full, this is a long and warm journey' +
                      'That year, collection of songs, review melodies, memories full, this is a long and warm journey' +
                      'That year, collection of songs, review melodies, memories full, this is a long and warm journey' +
                      'nora'
                    }

                  />
                  <VideoApp></VideoApp>
                </CardContent>
              </Card>

            </motion.div>

          )

          : (

            <motion.div

              style={{ zIndex: 2, }}
              className="cp-transition__squares-wrapper"
              key="squares"
              variants={wrapperVariants}
              initial="initial"
              animate="animate"
              exit="exit"
            >
              <Swiper
                key={`slid-mai}`}
                mousewheel={true}
                ref={swiperRef}
                lazy={true}
                effect= "flip"
                
                spaceBetween={3}
                slidesPerView={(isMobile) ? 1 : 3}
                onSlideChange={(index) => {myFunciton(index.activeIndex); /* setTitlePage(Itmes[index.activeIndex].title);  */}}
                onSwiper={(swiper) => { /*setLoadSlides(true);*/  if (seekItemCliked>0) { swiper.slideTo(seekItemCliked) }else{swiper.slideTo(ActiveItem)} }}
              >
                {renderSquares()}
              </Swiper>
            </motion.div>

          )

        }

      </AnimatePresence>

    </div>

  )

}