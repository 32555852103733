import React from 'react';
import cx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import TextInfoContent from '@mui-treasury/components/content/textInfo';
import { useFourThreeCardMediaStyles } from '@mui-treasury/styles/cardMedia/fourThree';
import { useN04TextInfoContentStyles } from '@mui-treasury/styles/textInfoContent/n04';
import { useOverShadowStyles } from '@mui-treasury/styles/shadow/over';

const useStylescard = makeStyles(() => ({
  root: {
    maxWidth: 343,
    margin: 'auto',
    borderRadius: 12,
    padding: 12,
  },
  media: {
    borderRadius: 6,
  },
}));

export default function MycardSlider({set} ) {


  const styles = useStylescard();
  const mediaStyles = useFourThreeCardMediaStyles();
  const textCardContentStyles = useN04TextInfoContentStyles();
  const shadowStyles = useOverShadowStyles({ inactive: true });

 
  
  
  //https://images.unsplash.com/photo-1500462918059-b1a0cb512f1d?ixlib=rb-1.2.1&auto=format&fit=crop&w=934&q=80
  return (
    <Card key={`cardSlide${set.Id}`} className={cx(styles.root, shadowStyles.root)} >
      <CardMedia
        className={cx(styles.media, mediaStyles.root,'swiper-lazy')}
        image={
          `https://www.bearfilms.com/tour/content2/${set.Directory}/${set.previewvidposter}`
        }
      />
      <div className="swiper-lazy-preloader"></div>
      <CardContent>
        <TextInfoContent 
          
          classes={textCardContentStyles}
          overline={set.title}
          heading={'Scene'}
          body={
            'That year, collection of songs, review melodies, memories full, this is a long and warm journey'
          }
        />
      </CardContent>
    </Card>
  );
};
